<template>
    <div v-if="dealer != null && dealer.dealer != null">
        <seo-meta></seo-meta>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>


        <breadcrumbs></breadcrumbs>


        <div class="wrapper">

            <div class="pagetitle" v-if="dealer && dealer.dealer">
                <h1>{{this.dealer.dealer.display_name}}</h1>
            </div>

        </div>

        <masthead></masthead>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="wrapper">

                <div class="dealerdetails">

                    <div class="dealerdetails__left">

                        <div class="dealerdetails__dealer">
                            <div class="dealerdetails__dealer_top">
                                <div class="dealerdetails__dealer_topleft">
                                    <div class="avatar avatar--medium" :style="getAvatar()"></div>
                                </div>
                                <div class="dealerdetails__dealer_topright">
                                    <h2>{{this.dealer.dealer.display_name}}</h2>
                                    <div class="stars stars--med" v-if="true==false">
                                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+100"></span>
                                    </div>
                                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                                </div>
                            </div>
                            <div class="dealerdetails__dealer_bottom">
                                <address>
                                    {{ formattedAddress1 }}<br/>
                                    {{ formattedAddress2 }}<br/>
                                    {{ formattedAddress3 }} {{ formattedAddress4 }}
                                </address>
                                <span class="telephone">{{ formattedPhone }}</span>
                                <span class="web"><a  rel="noopener" :href="unformattedWeb" target="_blank" class="green">{{ formattedWeb }}</a></span>
                                <div class="distance" v-if="getDistance() > 0">
                                    <span class="location"></span> {{ getDistance() }} km
                                </div>
                            </div>
                        </div>

                        <div class="dealerdetails__info" data-class="info-expand" v-if="formattedAbout.length > 0">
                            <span v-if="!readMoreActivated">{{ formattedAbout }}</span>
                            <div v-if="!readMoreActivated && formattedAbout && formattedAbout.length > 0 && formattedAboutFull.length > 200">
                                <a href="javascript:void(0);" class="infolink infolink--more" data-class="info-more" v-on:click="toggleShowMore()">More</a>
                            </div>
                            <span v-if="readMoreActivated">{{ formattedAboutFull }}</span>
                            <div v-if="readMoreActivated">
                                <a href="javascript:void(0)" class="infolink infolink--less" data-class="info-less" v-on:click="toggleShowMore()">Less</a>
                            </div>
                        </div>

                        <div class="grid grid--usedcars grid--usedcars--narrow">
                            <div class="usedcars_flex_item usedcars_flex_item--right">
                                <car-listings :currPage="page"></car-listings>
                            </div>
                        </div>

                    </div>

                    <div class="dealerdetails__right">

                        <dealer-details-right-dealer
                            :data="this.dealer"
                            :hideContactFormPlaceholder="this.hideContactFormPlaceholder"
                            detailsModifier="dealerdetails__directions_details--desktop"></dealer-details-right-dealer>


                    </div>

                </div>

            </div>

        </div>
        <floating-buttons-dealer :dealer="this.dealer"></floating-buttons-dealer>
        <modal-status-message v-if="modalStatusMessageOpen" :msg="statusMessage"></modal-status-message>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import FloatingButtonsDealer from '@/components/partials/FloatingButtonsDealer'
    import Masthead from  '@/components/partials/Masthead'
    import CarListings from '@/components/partials/CarListings'
    import DealerDetailsRightDealer from '@/components/partials/DealerDetailsRightDealer'
    import {mapMutations, mapState, mapActions} from "vuex"
    import SeoMeta from "../components/partials/SeoMeta"
    import Loading from 'vue-loading-overlay'
    var numeral = require('numeral')
    import ModalStatusMessage from "../components/partials/ModalStatusMessage"
    import EventBus from "../event-bus"
    import { isMobile } from 'mobile-device-detect'

    export default {
        name: "DealerDetails",
        components: {
            ModalStatusMessage,
            MainHeader,
            MainFooter,
            DealerDetailsRightDealer,
            CarListings,
            Masthead,
            Breadcrumbs,
            FloatingButtonsDealer,
            Loading,
            SeoMeta
        },
        props: {
            dealerSlug: {
                type: String,
                required: true
            },
            page: {
                type: String,
                required: false
            }
        },
        data: function() {
            return {
                hideContactFormPlaceholder: true,
                fullPage: true,
                readMoreActivated: false,
                aboutText:'',
                is_simi: false,
                checked: false,
                statusMessage: null,
                modalStatusMessageOpen: false,
                distance: 0,
            }
        },
        serverPrefetch() {
            return this.getDealer();
            //return this.fetchSearchDealer(process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?dealer=' + this.dealerSlug);
        },
        mounted() {

            if(this.page){
                this.setCurrentPage(this.page);
            }else{
                this.setCurrentPage(1);
            }

            this.setCurrentPageName('dealerDetails');
            this.setInternalSource(null);
            this.getDealerQuery();
            this.setUrl(process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?dealer=' + this.dealerSlug);

            if (!this.dealer) {
                this.getDealer();
            }else{
                this.updateBreadCrumb();
            }

            if(!this.searchResultData){
                this.fetchSearchDealer(this.url);
            }

            EventBus.$on('closeModal', function () {
                that.modalStatusMessageOpen = false;
            })

            EventBus.$on('openStatusMessageModal', function (params) {
                that.statusMessage = params.msg;
                that.modalStatusMessageOpen = true;
            })

            EventBus.$on('setIsLoading', function(){
                that.setIsLoading(true);
            })

            EventBus.$on('unsetIsLoading', function(){
                that.setIsLoading(false);
            })

            let that = this;
            window.addEventListener('popstate', function(event) {
                let myPage = typeof that.page === 'undefined' ? 1 : that.page;
                that.fetchSearchDealer(that.url.replace(/page=(\d+)/,'page='+ myPage));
            });

        },
        computed: {
            ...mapState([
                'makes',
                'models',
                'years',

                'prices',
                'monthlyPrice',
                'deposits',
                'monthlyPrices',
                'locations',
                'counties',
                'mileages',
                'kilometres',
                'engineSizes',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',
                'financeDeposits',
                'searchMake',
                'searchMakeDesktop',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',
                'searchFinanceDeposit',

                'resultsLoaded',
                'searchResults',
                'url',
                'currentPage',
                'urlParams',
                'isLoading'
            ]),
            dealer() {
                return this.$store.state.dealers.dealer[this.dealerSlug];
            },
            searchResultData () {

                if(this.currentPage && this.currentPage > 1){

                    this.setUrl(this.url + '&page=' + this.currentPage);
                }

                if(this.$route.params.sortby){
                    this.setUrl(this.url + '&sortby=' + this.$route.params.sortby);
                }

                this.setUrl(this.url);

                let res =  this.$store.state.searchResults[this.url];

                if(res != null){
                    this.setTotal(res.hits.total.value);
                    this.setTo(res.pagination.to);
                    this.setFrom(res.pagination.from);
                    this.setPerPage(res.pagination.perPage);
                    this.setCurrentPage(res.pagination.currentPage);
                }

                return res;
            },
            formattedAbout(){
                let text = '';
                if(this.dealer != null && this.dealer.dealer!= null && this.dealer.dealer.about_us != null){
                    text = this.dealer.dealer.about_us.length > 200 ? this.dealer.dealer.about_us.slice(0, 200) + ' ...' : this.dealer.dealer.about_us;
                }
                return text;
            },
            formattedAboutFull(){
                return  this.dealer.dealer.about_us;
            },
            formattedAddress1() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address1 != null ? this.dealer.dealer.address.address1 : '';
            },

            formattedAddress2() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address2 != null ? this.dealer.dealer.address.address2 : '';
            },

            formattedAddress3() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address3 != null ? this.dealer.dealer.address.address3 : '';
            },

            formattedAddress4() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address4 != null ? this.dealer.dealer.address.address4 : '';
            },

            formattedPhone() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.phone != null ? this.dealer.dealer.phone : '';
            },

            formattedWeb() {
                let res = this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.web != null ? this.dealer.dealer.web : '';
                return res.replace(/(^\w+:|^)\/\/www\./, '').replace(/\/+$/, '');

            },
            unformattedWeb() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.web != null ? this.dealer.dealer.web : '';
            },
            numberStars() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? Math.round(this.dealer.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? numeral(this.dealer.dealer.average_rating).format('0.00') : 0;
            }
        },
        methods: {
            ...mapMutations([
                'setMakes',
                'setModels',
                'setYears',
                'setPrices',
                'setLocations',
                'setCounties',
                'setMileages',
                'setKilometres',
                'setEngineSizes',
                'setTransmissionTypes',
                'setFuelTypes',
                'setBodyTypes',
                'setColours',
                'setSeats',
                'setDoors',
                'setMonthlyPrices',
                'setSafetyRatings',
                'setFuelConsumptions',
                'setEnvironmentals',
                'setEnginePowers',
                'setAccelerations',
                'setFinanceDeposits',
                'setOpenMobileRefine',
                'setSearchMake',
                'setSearchMakeDesktop',
                'unsetSearchMake',
                'setSearchModel',
                'setSearchModelDesktop',
                'unsetSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchKilometresFrom',
                'setSearchKilometresTo',
                'setSearchMonthlyPricesFrom',
                'setSearchMonthlyPricesTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setSearchFinanceDeposit',
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPage',
                'setCurrentPageName',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars',
                'setDealer',
                'setInternalSource'
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction'
            ]),
            getAvatar(){
              return this.dealer.dealer.team_members != null && this.dealer.dealer.team_members.length > 0 ? 'background-image: url(' + this.dealer.dealer.team_members[0].photo_url + ')' : '';
            },
            toggleShowMore(){
                this.readMoreActivated = !this.readMoreActivated;
            },
            getDealerQuery(){
                let url = process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?dealer=' + this.dealerSlug;

                let objParams = {};
                let arrUrlParams = [];
                if(this.dealerSlug != null) {
                    arrUrlParams = this.dealerSlug.split('&');
                    arrUrlParams.forEach((val) => {
                        let arr = val.split('=');
                        objParams[arr[0]] = arr[1];
                    });
                }

                if (objParams.sortBy != null) {
                    this.setSearchOrderBy(objParams.sortBy);
                }else{
                    this.setSearchOrderBy('price-desc');
                }

                if (objParams.page != null) {
                    this.setRequestedPage(objParams.page);
                }else{
                    this.setRequestedPage(1);
                }

                this.setUrl(url);
            },
            async getDealer() {
                this.setIsLoading(true);
                await this.$store.dispatch('dealers/fetchDealer', this.dealerSlug);
                this.setDealer(this.dealer.dealer);
                if(this.dealer.dealer == null || this.dealer.length == 0){
                    return this.$router.push('/not-found');
                }
                this.setIsLoading(false);
                this.updateBreadCrumb();
            },
            async fetchSearchDealer (url) {
                this.setIsLoading(true);
                await this.$store.dispatch('fetchSearchDealer', url);
                this.setIsLoading(false);
            },
            updateBreadCrumb(){
                if (this.dealer.dealer == null){
                    return this.$router.push('/not-found');
                }

                let dealerName = this.dealer.dealer.display_name;

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setLevelOne',  false);
                this.$store.commit('breadcrumbs/setFirstLevelName',  '');
                this.$store.commit('breadcrumbs/setLevelTwo',  false);

                this.$store.commit('breadcrumbs/setSecondLevelName',  '');
                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelFour',  false);
                this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                this.$store.commit('breadcrumbs/setLeafLevelName',  dealerName);


            },
            getDistance(){
                if(typeof window === 'undefined' || this.dealer.dealer.address.map_long == null || this.dealer.dealer.address.map_long == "" || isMobile === false ){
                    return 0;
                }
                let that = this;
                window.navigator.geolocation.getCurrentPosition(function(pos) {
                    var lat = pos.coords.latitude;
                    var lon = pos.coords.longitude;
                    that.distance = that.calculateDistance(lon, lat, that.dealer.dealer.address.map_long, that.dealer.dealer.address.map_lat);
                })
                return this.distance > 10 ? parseFloat(this.distance).toFixed(0) : parseFloat(this.distance).toFixed(1);
            },
            calculateDistance(lon1, lat1, lon2, lat2) {
                /** Converts numeric degrees to radians */
                if (typeof(Number.prototype.toRad) === "undefined") {
                    Number.prototype.toRad = function() {
                        return this * Math.PI / 180;
                    }
                }
                var R = 6371; // Radius of the earth in km
                var dLat = ( lat2 - lat1 ).toRad();  // Javascript functions in radians
                var dLon = (lon2-lon1).toRad();
                var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
                    Math.sin(dLon/2) * Math.sin(dLon/2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                var d = R * c; // Distance in km
                return d;
            }
        }
    }
</script>

<style>

    .floatingbuttons__button--contact{
        border-right: 1px solid white;
    }

</style>
