<template>
    <div>
        <seo-meta></seo-meta>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>

        <breadcrumbs></breadcrumbs>

        <div class="wrapper">

            <div class="pagetitle" v-if="dealerReviews && dealerReviews.dealer">
                <h1>{{this.dealerReviews.dealer.display_name}}</h1>
            </div>

        </div>

        <masthead></masthead>

        <div class="wrapper wrapper--grey wrapper--full" v-if="dealerReviews">
            <div class="wrapper">

                <div class="dealerdetails">

                    <div class="dealerdetails__left">

                        <div class="dealerdetails__reviews dealerdetails__reviews--left">
                            <div class="dealerdetails__reviews_top">
                                <div class="dealerdetails__reviews_topleft">
                                    <h2>Reviews</h2>
                                    <div class="stars stars--large">
                                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+5000"></span>
                                    </div>
                                    <small>{{ formattedAverageRating }}/5</small>
                                </div>
                                <div class="dealerdetails__reviews_topright" style="display: none">
                                    <label for="sortby">Sort by:</label>
                                    <select name="sortby" id="sortby" class="form-control">
                                        <option value="">Most Relevant</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="dealerReviews.reviews && dealerReviews.reviews.data">
                                <div  v-for="(item,index) in dealerReviews.reviews.data"  v-bind:key="index" class="dealerdetails__reviews_review">
                                    <review-avatar :item="item.reviewer_name"></review-avatar>
                                    <div class="dealerdetails__reviews_detail">
                                        <h3>{{item.reviewer_name }}</h3>
                                        <div class="dealerdetails__reviews_rating">
                                            <div class="stars">
                                                <span v-for="(star,idx) in item.rating" v-bind:key="idx"></span>
                                                <span class="stars__empty" v-for="(star,idx) in (5 - item.rating)" v-bind:key="idx+1000"></span>
                                            </div>
                                            <small>{{fromNow(item.created_at)}}</small>
                                        </div>
                                        <p>{{item.review }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dealerdetails__right">

                        <dealer-details-right-dealer :data="this.dealerReviews"></dealer-details-right-dealer>

                    </div>

                </div>

            </div>

        </div>

        <!--<floating-buttons-dealer></floating-buttons-dealer>-->
        <main-footer></main-footer>
    </div>
</template>

<script>

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import Masthead from  '@/components/partials/Masthead'
    import DealerDetailsRightDealer from '@/components/partials/DealerDetailsRightDealer'
    import {mapMutations, mapState, mapActions} from "vuex"
    import SeoMeta from "../components/partials/SeoMeta"
    import Loading from 'vue-loading-overlay'
    import ReviewAvatar from '../components/partials/ReviewAvatar'
    var numeral = require('numeral');
    var moment = require('moment');

    export default {
        name: "DealerReviews",
        components: {
            MainHeader,
            MainFooter,
            DealerDetailsRightDealer,
            Masthead,
            Breadcrumbs,
            Loading,
            SeoMeta,
            ReviewAvatar
            // FloatingButtonsDealer,
        },
        props: {
            dealerSlug: {
                type: String,
                required: true
            },
            adId: {
                type: String,
                required: false
            }
        },
        data: function() {
            return {
                fullPage: true,
            }
        },
        serverPrefetch() {
            return this.getDealerReviews();
        },
        mounted() {

            if(!this.dealerReviews){
                this.getDealerReviews();
            }

            this.updateBreadCrumb();
        },
        computed: {
            ...mapState([
                'makes',
                'models',
                'years',

                'prices',
                'monthlyPrice',
                'deposits',
                'monthlyPrices',
                'locations',
                'counties',
                'mileages',
                'kilometres',
                'engineSizes',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',
                'financeDeposits',
                'searchMake',
                'searchMakeDesktop',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',
                'searchFinanceDeposit',

                'resultsLoaded',
                'searchResults',
                'url',
                'urlParams',
                'isLoading'
            ]),
            dealerReviews() {
                return this.$store.state.dealers.dealerReviews[this.dealerSlug];
            },
            numberStars() {
                return this.dealerReviews != null && this.dealerReviews.dealer != null && this.dealerReviews.dealer != null && this.dealerReviews.dealer.average_rating != null ? Math.round(this.dealerReviews.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.dealerReviews != null && this.dealerReviews.dealer != null && this.dealerReviews.dealer != null && this.dealerReviews.dealer.average_rating != null ? numeral(this.dealerReviews.dealer.average_rating).format('0.00') : 0;
            }
        },
        methods: {
            fromNow(date) {
                return moment(date).fromNow();
            },
            ...mapMutations([
                'setMakes',
                'setModels',
                'setYears',
                'setPrices',
                'setLocations',
                'setCounties',
                'setMileages',
                'setKilometres',
                'setEngineSizes',
                'setTransmissionTypes',
                'setFuelTypes',
                'setBodyTypes',
                'setColours',
                'setSeats',
                'setDoors',
                'setMonthlyPrices',
                'setSafetyRatings',
                'setFuelConsumptions',
                'setEnvironmentals',
                'setEnginePowers',
                'setAccelerations',
                'setFinanceDeposits',
                'setOpenMobileRefine',
                'setSearchMake',
                'setSearchMakeDesktop',
                'unsetSearchMake',
                'setSearchModel',
                'setSearchModelDesktop',
                'unsetSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchKilometresFrom',
                'setSearchKilometresTo',
                'setSearchMonthlyPricesFrom',
                'setSearchMonthlyPricesTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setSearchFinanceDeposit',
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPage',
                'setCurrentPageName',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars',
                'setDealer',
                'setInternalSource'
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction'
            ]),
            async getDealerReviews() {
                this.setIsLoading(true);
                await this.$store.dispatch('dealers/fetchDealerReviews', this.dealerSlug);
                this.setIsLoading(false);
                this.updateBreadCrumb();
            },
            updateBreadCrumb(){

                if(this.dealerReviews == null){
                    return false;
                }

                if(!this.dealerReviews.dealer){
                    return false;
                }

                let dealerName = this.dealerReviews.dealer.display_name;
                let dealerSlug = this.dealerReviews.dealer.slug;

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  dealerName);

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setSecondLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelFour',  false);
                this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                this.$store.commit('breadcrumbs/setLeafLevelName',  'Reviews');
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/dealer/' + dealerSlug);

            }
        }
    }
</script>

<style scoped>

</style>
