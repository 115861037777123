<template>
    <div>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>

        <breadcrumbs></breadcrumbs>

        <div class="wrapper">

            <div class="pagetitle" v-if="dealer && dealer.dealer">
                <h1>{{this.dealer.dealer.display_name}}</h1>
            </div>

        </div>

        <masthead></masthead>

        <div class="wrapper wrapper--grey wrapper--full" v-if="dealer">
            <div class="wrapper">

                <div class="dealerdetails">

                    <div class="dealerdetails__left">
                        <div class="grid grid--usedcars grid--usedcars--narrow">
                            <div class="usedcars_flex_item usedcars_flex_item--right">
                                <div class="directions">
                                    <div class="directions__header">
                                        <div class="directions__headertext">
                                            <h1 class="pagetitle">Directions</h1>
                                        </div>
                                    </div>
                                    <div class="form-wrapper">
                                        <form action="javascript:void(0)">
                                            <label for="startLocation">From:</label>
                                            <input type="text"
                                                   name="startLocation"
                                                   :placeholder=placeholderMap
                                                   id="startLocation"
                                                   v-model="startLocation"
                                                   v-on:keyup.enter="callGmaps()"
                                            >
                                            <a v-on:click="callGmaps()"
                                               class="btn-call-maps"
                                               href="javascript:void(0)">Go</a>
                                        </form>

                                        <div class="form-divider" id="bigmap"></div>

                                        <div class="table-wrapper">
                                            <div>
                                                <div>
                                                    <div v-show="this.directions">
                                                        <table class="ter-directions-top">
                                                            <tbody>
                                                            <tr>
                                                                <td>
                                                                    <img
                                                                        src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EA%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A"
                                                                    >
                                                                </td>
                                                                <td class="location-mark">
                                                                    {{this.startLocation | capitalize}}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div v-show="this.directions">
                                                        <div class="adp-summary">
                                                            <span>{{ this.distance }}</span>
                                                            <span>. &nbsp;About {{ this.duration }}.</span>
                                                        </div>
                                                        <div>
                                                            <table class="ter-directions">
                                                                <tbody>
                                                                    <tr
                                                                        v-for="(direction, index) in directions"
                                                                        :key="index"
                                                                        data-leg-index="0"
                                                                        data-step-index="0"
                                                                    >
                                                                        <td>
                                                                            {{ index + 1 }}.
                                                                        </td>

                                                                        <td v-html="direction.instructions">
                                                                        </td>

                                                                        <td>
                                                                            <div>
                                                                                {{direction.distance.text}}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div>
                                                            <table class="ter-directions-bottom">
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img src="data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20width%3D%2227px%22%20height%3D%2243px%22%20viewBox%3D%220%200%2027%2043%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cdefs%3E%0A%3Cpath%20id%3D%22a%22%20d%3D%22m12.5%200c-6.9039%200-12.5%205.5961-12.5%2012.5%200%201.8859%200.54297%203.7461%201.4414%205.4617%203.425%206.6156%2010.216%2013.566%2010.216%2022.195%200%200.46562%200.37734%200.84297%200.84297%200.84297s0.84297-0.37734%200.84297-0.84297c0-8.6289%206.7906-15.58%2010.216-22.195%200.89844-1.7156%201.4414-3.5758%201.4414-5.4617%200-6.9039-5.5961-12.5-12.5-12.5z%22%2F%3E%0A%3C%2Fdefs%3E%0A%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%3Cg%20transform%3D%22translate(1%201)%22%3E%0A%3Cuse%20fill%3D%22%23EA4335%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%0A%3Cpath%20d%3D%22m12.5-0.5c7.18%200%2013%205.82%2013%2013%200%201.8995-0.52398%203.8328-1.4974%205.6916-0.91575%201.7688-1.0177%201.9307-4.169%206.7789-4.2579%206.5508-5.9907%2010.447-5.9907%2015.187%200%200.74177-0.6012%201.343-1.343%201.343s-1.343-0.6012-1.343-1.343c0-4.7396-1.7327-8.6358-5.9907-15.187-3.1512-4.8482-3.2532-5.01-4.1679-6.7768-0.97449-1.8608-1.4985-3.7942-1.4985-5.6937%200-7.18%205.82-13%2013-13z%22%20stroke%3D%22%23fff%22%2F%3E%0A%3C%2Fg%3E%0A%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%220.3em%22%20x%3D%2214%22%20y%3D%2215%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3EB%3C%2Ftext%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A" alt="tag">
                                                                    </td>
                                                                    <td class="location-mark">
                                                                        {{formattedAddress1}},
                                                                        {{formattedAddress2}},
                                                                        {{formattedAddress3}}
                                                                        {{formattedAddress4}}.
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="map-footer">
                                                        Map data ©{{ new Date().getFullYear() }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dealerdetails__right">
                        <dealer-details-right-dealer
                            :data="this.dealer"
                            :hideContactFormPlaceholder="this.hideContactFormPlaceholder"
                            detailsModifier="dealerdetails__directions_details--desktop"
                        ></dealer-details-right-dealer>

                    </div>

                </div>

            </div>

        </div>

        <!--<floating-buttons-dealer></floating-buttons-dealer>-->
        <main-footer></main-footer>
    </div>
</template>

<script>

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import Masthead from '@/components/partials/Masthead'
    import DealerDetailsRightDealer from '@/components/partials/DealerDetailsRightDealer'
    import {mapMutations, mapState, mapActions} from "vuex"
    import Loading from 'vue-loading-overlay';
    import { isMobile } from 'mobile-device-detect';

    var numeral = require('numeral');

    export default {
        name: "DealerDirections",
        components: {
            MainHeader,
            MainFooter,
            DealerDetailsRightDealer,
            Masthead,
            Breadcrumbs,
            Loading
        },
        props: {
            dealerSlug: {
                type: String,
                required: true
            },
            adId: {
                type: String,
                required: false
            }
        },
        data: function () {
            return {
                hideContactFormPlaceholder:true,
                mapsUrl: "https://maps.googleapis.com/maps/api/js?key=" + process.env.VUE_APP_GOOGLE_API_KEY + "&callback=initMap",
                startLocationMobile: null,
                distance: "",
                duration: "",
                directions: null,
                readMoreActivated: false,
                aboutText: '',
                is_simi: false,
                fullPage: true,
                checked: false,
                dealerAds: [],
                vehicleId: null,
                vehicle: null,
                price: null,
                year: null,
                make: null,
                model: null,
                variant: null,
                mileage: null,
                cashDeposit: null,
                tradeInValue: null,
                tradeInOutstanding: null,
                selected: null,
                token: null,
                proposalReference: null,
            }
        },
        serverPrefetch() {
            this.$ssrContext.description = '';
            this.$ssrContext.keywords = '';
            this.$ssrContext.robots = '';
            return this.getDealer();
        },
        mounted() {
            this.setUrl(process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?dealer=' + this.dealerSlug);
            if (!this.dealer) {
                this.getDealer();
            } else {
                this.setDealerMap();
            }

            this.updateBreadCrumb();
        },
        computed: {
            ...mapState([
                'makes',
                'models',
                'years',

                'prices',
                'monthlyPrice',
                'deposits',
                'monthlyPrices',
                'locations',
                'counties',
                'mileages',
                'kilometres',
                'engineSizes',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',
                'financeDeposits',
                'searchMake',
                'searchMakeDesktop',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',
                'searchFinanceDeposit',

                'resultsLoaded',
                'searchResults',
                'url',
                'urlParams',
                'isLoading',
                'mapStartLocation'
            ]),
            startLocation: {
                set(location) {
                    this.setMapStartLocation(location);
                },
                get() {
                    return this.$store.state.mapStartLocation
                }
            },
            placeholderMap(){
                return isMobile ? 'Current location' : 'Starting from';
            },
            showMap() {
                return this.$router.currentRoute.name != 'dealer-directions';
            },
            dealer() {
                return this.$store.state.dealers.dealer[this.dealerSlug];
            },
            checkReadOnly() {
                return (this.adId && this.dealerAds.length > 0 || this.selected)
            },
            formattedAbout() {
                let text = '';
                if (this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.about_us != null) {
                    text = this.dealer.dealer.about_us.length > 200 ? this.dealer.dealer.about_us.slice(0, 200) + ' ...' : this.dealer.dealer.about_us;
                }
                return text;
            },
            formattedAddress1() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address1 != null ? this.dealer.dealer.address.address1 : '';
            },

            formattedAddress2() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address2 != null ? this.dealer.dealer.address.address2 : '';
            },

            formattedAddress3() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address3 != null ? this.dealer.dealer.address.address3 : '';
            },

            formattedAddress4() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address4 != null ? this.dealer.dealer.address.address4 : '';
            },
            formattedPhone() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.phone != null ? this.dealer.dealer.phone : '';
            },

            formattedWeb() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.web != null ? this.dealer.dealer.web : '';
            },
            numberStars() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? Math.round(this.dealer.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? numeral(this.dealer.dealer.average_rating).format('0.00') : 0;
            },
            mapCenter() {
                if (this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.map_lat != null && this.dealer.dealer.address.map_long != null) {
                    return {
                        lat: parseFloat(this.dealer.dealer.address.map_lat),
                        lng: parseFloat(this.dealer.dealer.address.map_long)
                    };
                } else {
                    return {lat: 0, lng: 0};
                }
            },
            formattedLatLon() {
                return this.dealer != null && this.dealer.dealer.address.map_lat != null && this.dealer.dealer.address.map_long != null ? this.dealer.dealer.address.map_lat + ', ' + this.dealer.dealer.address.map_long : '';
            },

            zoom() {
                return this.dealer != null && this.dealer.dealer.address != null && this.dealer.dealer.address.map_zoom ? parseInt(this.dealer.dealer.address.map_zoom) : 0;
            },
        },
        methods: {
            ...mapMutations([
                'setMakes',
                'setModels',
                'setYears',
                'setPrices',
                'setLocations',
                'setCounties',
                'setMileages',
                'setKilometres',
                'setEngineSizes',
                'setTransmissionTypes',
                'setFuelTypes',
                'setBodyTypes',
                'setColours',
                'setSeats',
                'setDoors',
                'setMonthlyPrices',
                'setSafetyRatings',
                'setFuelConsumptions',
                'setEnvironmentals',
                'setEnginePowers',
                'setAccelerations',
                'setFinanceDeposits',
                'setOpenMobileRefine',
                'setSearchMake',
                'setSearchMakeDesktop',
                'unsetSearchMake',
                'setSearchModel',
                'setSearchModelDesktop',
                'unsetSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchKilometresFrom',
                'setSearchKilometresTo',
                'setSearchMonthlyPricesFrom',
                'setSearchMonthlyPricesTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setSearchFinanceDeposit',
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPage',
                'setCurrentPageName',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars',
                'setDealer',
                'setInternalSource'
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction'
            ]),
            toggleShowMore() {
                this.readMoreActivated = !this.readMoreActivated;
            },
            async getDealer() {
                this.setIsLoading(true);
                await this.$store.dispatch('dealers/fetchDealer', this.dealerSlug);
                this.setDealer(this.dealer.dealer);
                this.setIsLoading(false);
                //Set Dealer Map
                this.setDealerMap();
                this.updateBreadCrumb();
            },
            setDealerMap() {
                if (typeof window !== "undefined") {
                    if (this.checkGoogleMapsTag() == false) {
                        this.injectGoogleMaps();
                        this.callGmaps();
                    } else {
                        this.callGmaps();
                    }
                }
            },
            injectGoogleMaps() {
                // Create the script tag, set the appropriate attributes
                let script = document.createElement('script');
                script.src = this.mapsUrl;
                script.defer = true;
                script.async = true;
                // Append the 'script' element to 'head'
                document.head.appendChild(script);
            },
            callGeolocation(){
                let start = null;
                let end = null;
                let request = null;
                let that = this;
                //Direction service
                let directionsService = new window.google.maps.DirectionsService();
                let directionsRenderer = new window.google.maps.DirectionsRenderer();

                navigator.geolocation.getCurrentPosition(function(position) {
                    var myloc = new window.google.maps.Marker({
                        clickable: false,
                        icon: new window.google.maps.MarkerImage('https://maps.gstatic.com/mapfiles/mobile/mobileimgs2.png',
                            new window.google.maps.Size(22,22),
                            new window.google.maps.Point(0,18),
                            new window.google.maps.Point(11,11)),
                        shadow: null,
                        zIndex: 999,
                        map: window.map
                    });
                    var me = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    myloc.setPosition(me);

                    if(that.startLocation.length == 0){
                        that.startLocationMobile = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    }
                    start = that.startLocation.length == 0 ? that.startLocationMobile : that.startLocation + ' Ireland';
                    end = that.mapCenter;

                    if(typeof start == 'object' || start.length > 0) {
                        directionsRenderer.setMap(window.map);
                        request = {
                            origin: start,
                            destination: end,
                            travelMode: 'DRIVING'
                        };
                        directionsService.route(request, function (result, status) {
                            if (status == 'OK') {
                                directionsRenderer.setDirections(result);
                                that.directions = result.routes[0].legs[0].steps;
                                that.distance = result.routes[0].legs[0].distance.text;
                                that.duration = result.routes[0].legs[0].duration.text;
                            }
                        });
                    }

                }, function() {});
            },
            callManuelGeolocation(){
                let start = null;
                let end = null;
                let request = null;
                let that = this;
                //Direction service
                let directionsService = new window.google.maps.DirectionsService();
                let directionsRenderer = new window.google.maps.DirectionsRenderer();

                start = this.startLocation + ' Ireland';
                end = this.mapCenter;

                if(start.length > 0) {
                    directionsRenderer.setMap(window.map);
                    request = {
                        origin: start,
                        destination: end,
                        travelMode: 'DRIVING'
                    };

                    directionsService.route(request, function (result, status) {
                        if (status == 'OK') {
                            directionsRenderer.setDirections(result);
                            that.directions = result.routes[0].legs[0].steps;
                            that.distance = result.routes[0].legs[0].distance.text;
                            that.duration = result.routes[0].legs[0].duration.text;
                        }
                    });
                }

            },
            callGmaps() {
                // Attach your callback function to the `window` object
                let self = this;
                let permission = null;

                // let infoWindow;
                window.initMap = function () {
                    // JS API is loaded and available
                    window.map = new window.google.maps.Map(document.getElementById("bigmap"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                    if(isMobile){
                        if(typeof navigator.permissions === 'undefined'){
                            //If start location not empy call manual map
                            if(self.startLocation.length > 0){
                                self.callManuelGeolocation();
                            }else{
                                self.callGeolocation();
                            }
                        }else{
                            navigator.permissions.query({ name: 'geolocation' })
                                .then(result => {
                                    if (result.state === 'granted') {
                                        permission = 'granted';
                                    } else if (result.state === 'prompt') {
                                        permission = 'prompt';
                                    } else if (result.state === 'denied') {
                                        permission = 'denied';
                                    }
                                    if(permission == 'granted' || permission == 'prompt'){
                                        self.callGeolocation();
                                    }else{
                                        self.callManuelGeolocation();
                                    }
                                });
                        }
                    }else{
                        self.callManuelGeolocation();
                    }
                };

                if (typeof window.google !== 'undefined') {
                    window.map = new window.google.maps.Map(document.getElementById("bigmap"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });

                    if(isMobile){

                        if(typeof navigator.permissions === 'undefined'){
                            if(self.startLocation.length > 0){
                                self.callManuelGeolocation();
                            }else{
                                self.callGeolocation();
                            }
                        }else{
                            navigator.permissions.query({ name: 'geolocation' })
                                .then(result => {
                                    if (result.state === 'granted' ) {
                                        permission = 'granted';
                                    } else if (result.state === 'prompt') {
                                        permission = 'prompt';
                                    } else if (result.state === 'denied') {
                                        permission = 'denied';
                                    }
                                    if(permission == 'granted' || permission == 'prompt'){
                                        self.callGeolocation();
                                    }else{
                                        self.callManuelGeolocation();
                                    }
                                });
                        }
                    }else{
                        self.callManuelGeolocation();
                    }
                }
            },
            checkGoogleMapsTag() {
                var scripts = document.getElementsByTagName('script');
                for (var i = scripts.length; i--;) {
                    if (scripts[i].src == this.mapsUrl) return true;
                }
                return false;
            },
            ...mapMutations([
                'setMapStartLocation',
            ]),
            updateBreadCrumb(){
                if(this.dealer == null){
                    return false;
                }

                let dealerName = this.dealer.dealer.display_name;
                let dealerSlug = this.dealer.dealer.slug;

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  dealerName);

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setSecondLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelFour',  false);
                this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                this.$store.commit('breadcrumbs/setLeafLevelName',  'Directions');
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/dealer/' + dealerSlug);

            }
        }
    }
</script>

<style scoped>
</style>
