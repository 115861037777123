<template>
    <div class="masthead no-print" :style="getMasthead">
        <div class="wrapper">
            <div class="masthead__content">
                <div class="masthead__logo" :style="getLogoStyle"></div>
                <div v-show="isSimi" class="masthead__simi"></div>
                <div class="masthead__rating" v-if="true==false">
                    <div class="stars stars--masthead">
                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+1000"></span>
                    </div>
                    <small>{{ formattedAverageRating }}/5 </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    var numeral = require('numeral');
    import { isMobile } from 'mobile-device-detect';

    export default {
        name: "Masthead",
        computed: {
                ...mapState([
                    'dealer',
                ]),
            isSimi() {
                return this.dealer && this.dealer.is_simi ? true : false;
            },
            numberStars() {
                return this.dealer != null && this.dealer.average_rating != null ? Math.round(this.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.dealer != null && this.dealer.average_rating != null ? numeral(this.dealer.average_rating).format('0.00') : 0;
            },
            getLogoStyle(){
                return this.dealer != null && this.dealer.logo != null ? "background-image: url('" + process.env.VUE_APP_IMAGES_URL + '/' + this.dealer.logo + "')" :  "display:none";
            },
            getMasthead(){
                if(isMobile && this.dealer && this.dealer.masthead_mobile != null){
                    return "background-image: url('" + process.env.VUE_APP_IMAGES_URL + '/' + this.dealer.masthead_mobile + "')";
                }else{
                    return this.dealer != null && this.dealer.masthead != null ? "background-image: url('" + process.env.VUE_APP_IMAGES_URL + '/' + this.dealer.masthead + "')" : "display:block";
                }
            },
        }
    }
</script>

<style scoped>
</style>
