<template>
    <div class="dealerdetails__contact">
        <form method="post" @submit.prevent="sendEmail" @keydown="errors.clear($event.target.name)">
            <legend>Contact Seller via Email</legend>
            <div  class="form-error-top" v-if="Object.keys(errorResponse).length > 0">General problem. Please try again later.  </div>
            <label for="name">Your Name*</label>
            <input type="text" placeholder="Your Name*" class="form-control" id="name" v-model="name" name="name"
                   :class="errors.has('name') ? 'form-control--error' : null"/>

            <div v-if="errors.has('name')">
            <div class="form-error"
                  v-for="(error, index) in errors.get('name')" :key="index"
                  v-text="error"></div>
            </div>

            <label for="email">Your Email Address*</label>
            <input type="email" placeholder="Email Address*" class="form-control" id="email" v-model="email"
                   name="email"
                   :class="errors.has('email') ? 'form-control--error' : null"/>

            <div v-if="errors.has('email')">
            <div class="form-error"
                  v-for="(error, index) in errors.get('email')" :key="index"
                  v-text="error"></div>
            </div>

            <label for="phone">Phone Number</label>
            <input type="text" placeholder="Phone Number" class="form-control" id="phone" v-model="phone" name="phone"
                   :class="errors.has('phone') ? 'form-control--error' : null"/>

            <div v-if="errors.has('phone')">
            <div class="form-error"  v-for="(error, index) in errors.get('phone')"
                  :key="index"
                  v-text="error"></div>
            </div>

            <div class="dealerdetails__contact_group">
                <div class="dealerdetails__contact_col" v-show="this.dealer.id != 1">
                    <label>Do you have a trade in?</label>
                </div>
                <div class="dealerdetails__contact_col" v-show="this.dealer.id != 1">
                    <div class="dealerdetails__contact_col">
                        <input type="radio" class="custom-radio" name="tradein-seller-2" value="No" id="tradein-seller-2-no-1" :checked="!isTradeIn"  />
                        <label for="tradein-seller-2-no-1" class="custom-radio-label" @click="resetTradeIn"></label>
                        <label for="tradein-seller-2-no-1">No</label>
                    </div>
                    <div class="dealerdetails__contact_col dealerdetails__contact_col--nopadding">
                        <input type="radio" class="custom-radio" name="tradein-seller-2" value="Yes" id="tradein-seller-2-yes-1" :checked="isTradeIn" />
                        <label for="tradein-seller-2-yes-1" class="custom-radio-label"  @click="isTradeIn = true"></label>
                        <label for="tradein-seller-2-yes-1">Yes</label>
                    </div>
                </div>
                <div v-if="isTradeIn">
                    <label for="reg_number">Trade-in registration</label>
                    <input type="text" placeholder="Trade-in car registration" class="form-control" id="reg_number"
                           v-model="regNumber" name="reg_number"
                           :class="errors.has('reg_number') ? 'form-control--error' : null"/>

                    <div v-if="errors.has('reg_number')">
                    <div class="form-error"
                          v-for="(error, index) in errors.get('reg_number')" :key="index"
                          v-text="error"></div>
                    </div>
                </div>
            </div>
            <label for="enquiry">Enquiry</label>
            <textarea :placeholder="placeholder" class="form-control" v-model="enquiry" id="enquiry"
                      name="enquiry"
                      :class="errors.has('enquiry') ? 'form-control--error' : null"></textarea>

            <div v-if="errors.has('enquiry')">
            <div class="form-error"
                  v-for="(error, index) in errors.get('enquiry')"
                  :key="index" v-text="error"></div>
            </div>
            <input type="submit" value="Submit" :disabled="errors.any()">
        </form>
    </div>
</template>

<script>

    import EventBus from "../../../event-bus";

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }
    }

    export default {
        name: "DealerRightContactFormDealer",
        props: ['dealer', 'hidePlaceholder'],


        data: function () {
            return {
                dealerId: null,
                name: null,
                email: null,
                phone: null,
                enquiry: null,
                isTradeIn: false,
                tradeIn: false,
                regNumber: null,
                response: null,
                errorResponse: [],
                errors: new Errors(),
            };
        },

        mounted(){
            this.dealerId = this.dealer.id;
            this.loadEnquiryData();
        },

        computed: {
            placeholder() {
                if(this.hidePlaceholder === true){
                    return '';
                }else{
                    return 'I would like to enquire about this dealer';
                }
            }
        },

        methods: {
            sendEmail() {
                this.errorResponse = [];
                EventBus.$emit('setIsLoading');

                this.axios.post(process.env.VUE_APP_API_URL  + '/api/general-enquiry/send', {
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone,
                    'enquiry': this.enquiry == null || this.enquiry == "" ? this.placeholder : this.enquiry,
                    'dealer_id': this.dealerId,
                    'is_trade_in': this.isTradeIn,
                    'reg_number': this.isTradeIn ? this.regNumber : null,
                    'type': 'dealer'
                })
                    .then(response => {

                        EventBus.$emit('unsetIsLoading');
                        if(typeof response.data.status !== 'undefined' && response.data.status == 'error'){
                            EventBus.$emit('openStatusMessageModal', {msg:"Your enquiry has been sent. Thank you"});
                        }else{
                            EventBus.$emit('openStatusMessageModal', {msg:"Your enquiry has been sent. Thank you!"});
                        }


                        //Save Enquiry Data
                        let objEnquiry = {
                            name : this.name,
                            email: this.email,
                            phone: this.phone,
                            regNumber: this.regNumber,
                            isTradeIn: this.isTradeIn
                        };
                        this.saveEnquiryLocal(objEnquiry);

                        this.enquiry = null;
                        this.response = response;


                    })
                    .catch(error => {
                        EventBus.$emit('unsetIsLoading');
                        if(error && error.response.status == 422){
                            this.errors.record(error.response.data);
                        }else if(error && error.respoonse && error.response.data){
                            this.errorResponse = error.response.data;
                        }else{
                            this.errorResponse = {'error': 500} ;
                        }

                    });
            },

            loadEnquiryData(){
                let data = JSON.parse(sessionStorage.getItem('enquiry_data'));

                if(data != null){
                    this.name = data.name;
                    this.email = data.email;
                    this.phone = data.phone;
                    this.regNumber = data.regNumber;
                    this.isTradeIn = data.isTradeIn;
                }

            },
            saveEnquiryLocal(data){
                sessionStorage.setItem('enquiry_data', JSON.stringify(data));
            },

            resetTradeIn() {
                this.isTradeIn = false;
            }
        }
    }
</script>

<style scoped>

</style>
