<template>
    <div class="modal" style="display:flex">

        <div class="modalerror">
            <div class="modal__wrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>Error</h1>
                </div>
                <div class="modalcall__content">
                    <p>We had a problem processing your request! Please try again later.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "ModalErrorMessage",
        props: ['err'],
        data: function() {
            return {
            }
        },
        mounted(){
        },

        computed: {
        },

        methods: {
            closeModal() {
                EventBus.$emit('closeModal');
            },
        }
    }
</script>

<style scoped>

</style>
