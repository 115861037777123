<template>
    <div>
        <dealer-right-contact-form-dealer :dealer="this.data.dealer" :hidePlaceholder="hideContactFormPlaceholder"/>

        <div class="actionbutton actionbutton--blue" v-if="showFinance">
            <router-link :to="{path: '/dealer/' + getDealerSlug + '/finance'}">
                <div class="actionbutton__icon actionbutton__icon--finance"></div>
                <div class="actionbutton__text">Finance Options</div>
            </router-link>
        </div>

        <div class="dealerdetails__directions">
            <div class="dealerdetails__directions_details">
                <router-link :to="{path: '/dealer/' + getDealerSlug}">
                    <h3>{{ formattedDisplayName }}</h3>
                </router-link>
                <div class="dealerdetails__directions_rating">
                    <div class="stars stars--med" v-if="true==false">
                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+100"></span>
                    </div>
                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                </div>
                <address>
                    <div v-html="formattedFullAddress"></div>
                </address>
                <span class="telephone">{{ formattedPhone }}</span>
                <span class="web"><a target="_blank" rel="noopener" :href="formattedWeb" class="green">{{ formattedWeb }}</a></span>
            </div>

            <div class="dealerdetails__directions_map-wraper" v-if="showMap">
                <div id="map"></div>
            </div>

            <div class="dealerdetails__directions_content" v-if="showMap">
                <form action="javascript:void(0)">
                    <legend>Get Directions</legend>
                    <div class="dealerdetails__directions_formgroup">
                        <input name="location" :placeholder=placeholderMap class="form-control" v-model="startLocation">
                        <button v-on:click="goToMap()">Go</button>
                    </div>
                </form>
                <small>{{ formattedLatLon }}</small>
            </div>

        </div>

        <div v-if="data">
            <div class="dealerdetails__hours" v-if="data.dealer != null">
                <h2>Opening Hours</h2>
                <dealer-hours :hours="this.data.dealer.hours"/>
            </div>
        </div>

        <div class="dealerdetails__reviews" v-if="data != null && data.dealer != null && true==false">
            <h2>Reviews</h2>
            <div class="dealerdetails__reviews_avg">
                <small>{{ formattedAverageRating }}/5<span>avg</span></small>
                <div class="stars stars--large">
                    <span v-for="(star,idx) in numberStars" v-bind:key="idx+1000"></span>
                    <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+2000"></span>
                </div>
            </div>

            <dealer-small-review v-for="review in data.dealer.reviews" :review="review" :key="review.id" />

            <router-link :to="{ name: 'dealer-reviews', params: { dealerSlug:  data.dealer.slug }}" class="green">Read All Reviews</router-link>
        </div>

        <div class="dealerdetails__business">
            <h2>Business Details</h2>
            <dl>
                <dt>{{ formattedPrincipalTitle }}:</dt>
                <dd>{{ formattedPrincipalName }}</dd>
            </dl>
            <dl>
                <dt>Legal Status:</dt>
                <dd>{{ formattedLegalStatus }}</dd>
            </dl>
            <dl>
                <dt>In Business:</dt>
                <dd>{{ formattedInBusiness }}</dd>
            </dl>
            <dl>
                <dt>Memberships:</dt>
                <dd>
                    <div v-for="(item, key) in memberships" :key="key">
                        {{ item }}
                    </div>
                </dd>
            </dl>
            <dl>
                <dt>Number of Staff:</dt>
                <dd>{{ formattedStaffCount }}</dd>
            </dl>
            <dl>
                <dt>Franchises:</dt>
                <dd>{{ formattedFranchises }}</dd>
            </dl>

            <div class="media" v-if="isVideoAvailable">
                <div class="iframe-container">
                    <div id="player"></div>
                </div>
                <v-lazy-image :src="videoImage" :src-placeholder="placeholderLazyImg" v-if="!showIframe && isVideoAvailable" width="338" height="190" alt="Youtube Video" class="youtube-video"/>
                <div class="player" id="playerSimple"  slot="button-next" @click="playVideo" v-if="!showIframe && isVideoAvailable">
                    <div class="youtube-play"></div>
                </div>
            </div>
        </div>

        <div class="dealerdetails__team">
            <h2>Meet the Team</h2>

            <div class="dealerdetails__team_people" v-if="data.dealer">
                <dealer-team-member  v-for="teamMember in data.dealer.team_members" :team-member="teamMember" :key="teamMember.id" />
            </div>
        </div>
    </div>
</template>

<script>

    import DealerHours from '@/components/partials/panels/DealerHours'
    import DealerTeamMember from  '@/components/partials/panels/DealerTeamMember'
    import DealerSmallReview from '@/components/partials/panels/DealerSmallReview'
    import DealerRightContactFormDealer from "@/components/partials/panels/DealerRightContactFormDealer"
    import {mapState, mapMutations} from 'vuex'
    import EventBus from "../../event-bus"
    import { isMobile } from 'mobile-device-detect'
    import VLazyImage from "v-lazy-image"

    var numeral = require('numeral')
    var moment = require('moment')
    export default {
        name: "DealerDetailsRightDealer",
        components: {
            DealerRightContactFormDealer,
            DealerSmallReview,
            DealerTeamMember,
            DealerHours,
            VLazyImage
        },
        props: ['data', 'hideContactFormPlaceholder'],
        data: function () {
            return {
                mapsUrl: "https://maps.googleapis.com/maps/api/js?key=" + process.env.VUE_APP_GOOGLE_API_KEY + "&callback=initMap",
                startMapLocation: "",
                showIframe: false
            }
        },
        mounted(){
            if(this.$router.currentRoute.name != 'dealer-directions'){
                if(this.checkGoogleMapsTag() == false){
                    this.injectGoogleMaps();
                    this.callGmaps();
                }else{
                    this.callGmaps();
                }
            }
        },
        computed: {
            ...mapState([
                'mapStartLocation',
            ]),
            showFinance(){
                return this.data.dealer && this.data.dealer.show_finance_on_terrific == 1;
            },
            isVideoAvailable() {
                return this.data.dealer && this.data.dealer.youtube_id != null ? true : false;
            },
            videoImage() {
                return this.getVideoImage();
            },
            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            startLocation:{
              set(location){
                  this.setMapStartLocation(location);
              },
              get(){
                  return this.$store.state.mapStartLocation
              }
            },
            placeholderMap(){
                return isMobile ? 'Starting from current location' : 'Starting from';
            },
            showMap(){
                return this.$router.currentRoute.name != 'dealer-directions';
            },
            mapCenter() {
                if (this.data !=null && this.data.dealer != null && this.data.dealer.address.map_lat != null && this.data.dealer.address.map_long != null) {
                    return {
                        lat: parseFloat(this.data.dealer.address.map_lat),
                        lng: parseFloat(this.data.dealer.address.map_long)
                    };
                } else {
                    return {lat: 0, lng: 0};
                }
            },

            formattedLatLon() {
                return this.data !=null && this.data.dealer != null && this.data.dealer.address.map_lat != null && this.data.dealer.address.map_long != null ? this.data.dealer.address.map_lat + ', ' + this.data.dealer.address.map_long : '';
            },
            slug(){
                return this.data !=null && this.data.dealer != null ? this.data.dealer.slug : null;
            },
            zoom() {
                return this.data !=null && this.data.dealer != null && this.data.dealer.address.map_zoom ? parseInt(this.data.dealer.address.map_zoom) : 0;
            },

            formattedDisplayName() {
                return this.data != null && this.data.dealer != null && this.data.dealer != null ? this.data.dealer.display_name : '';
            },

            getDealerSlug(){
                return this.data != null && this.data.dealer != null  ? this.data.dealer.slug : '';
            },
            formattedFullAddress(){
                let textHtml = '';

                if(this.formattedAddress1.length > 0){
                    textHtml += this.formattedAddress1;
                }
                if(this.formattedAddress2.length > 0){
                    textHtml += "<br>" + this.formattedAddress2;
                }
                if(this.formattedAddress3.length > 0){
                    textHtml += "<br>" + this.formattedAddress3;
                }
                if(this.formattedAddress4.length > 0){
                    textHtml += "<br>" + this.formattedAddress4;
                }
                if(this.formattedAddress5.length > 0){
                    textHtml += "<br>" + this.formattedAddress5;
                }
                if(this.formattedCounty.length > 0){
                    textHtml += "<br>" + this.formattedCounty;
                }
                if(this.formattedPostcode.length > 0){
                    textHtml += "<br>" + this.formattedPostcode;
                }
                return textHtml;
            },
            formattedAddress1() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.address1 != null ? this.data.dealer.address.address1 : '';
            },

            formattedAddress2() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.address2 != null ? this.data.dealer.address.address2 : '';
            },

            formattedAddress3() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.address3 != null ? this.data.dealer.address.address3 : '';
            },

            formattedAddress4() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.address4 != null ? this.data.dealer.address.address4 : '';
            },

            formattedAddress5() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.address5 != null ? this.data.dealer.address.address5 : '';
            },

            formattedCounty() {
                return this.data != null && this.data.dealer != null && this.data.dealer.region != null ? 'Co. ' + this.data.dealer.region : '';
            },

            formattedPostcode() {
                return this.data != null && this.data.dealer != null && this.data.dealer.address.postcode != null ? this.data.dealer.address.postcode : '';
            },

            formattedPhone() {
                return this.data != null && this.data.dealer != null && this.data.dealer.phone != null ? this.data.dealer.phone : '';
            },

            formattedWeb() {
                return this.data != null && this.data.dealer != null && this.data.dealer.web != null ? this.data.dealer.web : '';
            },

            formattedAverageRating() {
                 return this.data != null && this.data.dealer != null && this.data.dealer != null && this.data.dealer.average_rating != null ? numeral(this.data.dealer.average_rating).format('0.00') : 0;
            },

            numberStars() {
                 return this.data != null && this.data.dealer != null && this.data.dealer != null && this.data.dealer.average_rating != null ? Math.round(this.data.dealer.average_rating) : 0;
            },

            formattedPrincipalTitle() {
                return this.data != null && this.data.dealer != null && this.data.dealer.principal_title != null ? this.data.dealer.principal_title : 'Dealer Principal';
            },

            formattedPrincipalName() {
                return this.data != null && this.data.dealer != null && this.data.dealer.principal != null ? this.data.dealer.principal : '';
            },

            formattedLegalStatus() {
                return this.data != null && this.data.dealer != null && this.data.dealer.legal_status != null ? this.data.dealer.legal_status : '';
            },

            formattedInBusiness() {
                return this.data.dealer != null && this.data.dealer.trading_since_date != null ? moment(this.data.dealer.trading_since_date, 'YYYY').fromNow(true) : '';
            },

            formattedSimi() {
                return this.data.dealer != null && this.data.dealer.is_simi ? 'SIMI' : '';
            },

            memberships() {
                let initialValue = this.data.dealer != null && this.data.dealer.is_simi ? 'SIMI' : '';
                let memberships = [];
                if(initialValue != ''){
                    memberships.push(initialValue);
                }

                if(this.data.dealer && this.data.dealer.memberships != null ){
                    this.data.dealer.memberships.forEach(function(value) {
                        memberships.push(value.organisation);
                    });
                }
                return memberships;
            },

            formattedStaffCount() {
                return this.data.dealer != null && this.data.dealer.num_staff != null ? numeral(this.data.dealer.num_staff).format('0') : '';
            },

            formattedFranchises() {
                return this.data.dealer != null && this.data.dealer.franchises.length > 0 ? this.data.dealer.franchises.join(', ') : 'Independent';
            },
            formattedYoutubeUrl() {
                return this.data.dealer && this.data.dealer.youtube_id != null ?  "https://www.youtube.com/embed/" + this.data.dealer.youtube_id + '?autoplay=1&mute=1' : '';
            }
        },

        methods: {
            openHirePurchaseModal() {
                EventBus.$emit('openHirePurchaseModal');
            },
            injectGoogleMaps(){
                // Create the script tag, set the appropriate attributes
                let script = document.createElement('script');
                script.src = this.mapsUrl;
                script.defer = true;
                script.async = true;
                // Append the 'script' element to 'head'
                document.head.appendChild(script);
            },
            callGmaps(){

                // Attach your callback function to the `window` object
                let self = this;
                // let infoWindow;
                window.initMap = function() {
                    // JS API is loaded and available
                    window.map = new window.google.maps.Map(document.getElementById("map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                };

                if(typeof window.google !== 'undefined'){

                    window.map = new window.google.maps.Map(document.getElementById("map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                }
            },
            checkGoogleMapsTag(){
                var scripts = document.getElementsByTagName('script');
                for (var i = scripts.length; i--;) {
                    if (scripts[i].src == this.mapsUrl) return true;
                }
                return false;
            },
            goToMap(){
                this.$router.push('/dealer/' + this.slug + '/directions');
            },
            playVideo(){
                this.showIframe = true;

                // 2. This code loads the IFrame Player API code asynchronously.
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                let myScript = document.createElement('script');
                let data = "" +
                    "                var player;\n" +
                    "                function onYouTubeIframeAPIReady() {\n" +
                    "                    player = new YT.Player('player', {\n" +
                    "                        width: '100%',\n" +
                    "                        videoId: '" + this.data.dealer.youtube_id + "',\n" +
                    "                        playerVars: { 'autoplay': 1, 'playsinline': 1 },\n" +
                    "                        events: {\n" +
                    "                            'onReady': onPlayerReady\n" +
                    "                        }\n" +
                    "                    });\n" +
                    "                }\n" +
                    "\n" +
                    "                // 4. The API will call this function when the video player is ready.\n" +
                    "                function onPlayerReady(event) {\n" +

                    "                    event.target.playVideo();\n" +
                    "                }" +

                    "";

                myScript.appendChild(document.createTextNode(data));
                document.body.appendChild(myScript);

            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.data.dealer.youtube_id;
                }
                return url;
            },
            ...mapMutations([
                'setMapStartLocation',
            ]),
        },
    }
</script>

<style scoped>
</style>
