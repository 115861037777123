<template>
    <div>
        <div class="floatingbuttons" :class="showPhone == true ? 'floatingbuttons--showphone' : ''">
            <div class="floatingbuttons__phonenumberdealer"><a :href="formattedPhoneNumber">{{ dealer.dealer.phone }}</a></div>
            <div class="floatingbuttons__wrapper">
                <a v-if="getCountPhoneNumbers() == 1" :href="formattedPhoneNumber" class="floatingbuttons__button floatingbuttons__button--red floatingbuttons__button--contact"><span>Phone</span></a>
                <a v-if="getCountPhoneNumbers() > 1" href="javascript:void(0)" class="floatingbuttons__button floatingbuttons__button--red floatingbuttons__button--contact" @click="togglePhoneModal"><span>Phone</span></a>
                <a href="#" class="floatingbuttons__button floatingbuttons__button--green floatingbuttons__button--enquire floatingbuttons__button--enquire-text" @click="openEmailSellerModal">Enquire</a>
            </div>
        </div>

        <modal-email-seller v-if="modalEmailSellerOpen" :dealer="dealer.dealer"></modal-email-seller>
        <modal-call-seller v-if="modalCallSellerOpen" :dealer="dealer.dealer"></modal-call-seller>
    </div>
</template>

<script>
    import ModalEmailSeller from "./ModalEmailSeller";
    import ModalCallSeller from "./ModalCallSeller";
    import EventBus from "../../event-bus";
    export default {
        name: "FloatingButtonsDealer",
        props: ['dealer'],
        components: {
            ModalEmailSeller,
            ModalCallSeller
        },

        data: function () {
            return {
                modalEmailSellerOpen: false,
                modalCallSellerOpen: false,
                showPhone: false,
            }
        },

        mounted() {
            let that = this;
            EventBus.$on('closeModal', function () {
                that.modalEmailSellerOpen = false;
                that.modalCallSellerOpen = false;
            });
        },

        computed:{
            formattedPhoneNumber() {
                if(this.dealer == null){
                    return false;
                }
                return 'tel:' + this.dealer.dealer.phone;
            }
        },

        methods: {
            getCountPhoneNumbers(){
                let sum = 0;
                if(typeof this.dealer.dealer.phone === 'undefined'){
                    return sum;
                }else{
                    if(this.dealer.dealer.mobile != null){
                        sum = 1;
                    }
                    sum = this.dealer.dealer.phone.split('/').length + sum;
                }
                return sum;
            },
            openEmailSellerModal() {
                this.modalEmailSellerOpen = true;
            },
            togglePhoneModal() {
                if (window != undefined) {
                    if (window.innerWidth < 1024) {
                        this.modalCallSellerOpen = true;
                    } else {
                        this.showPhone = !this.showPhone;
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
