<template>
    <div v-if="dealer && dealer.dealer">
        <seo-meta></seo-meta>
        <main-header></main-header>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>

        <breadcrumbs></breadcrumbs>

        <div class="wrapper">

            <div class="pagetitle" v-if="dealer && dealer.dealer">
                <h1>{{this.dealer.dealer.display_name}}</h1>
            </div>

        </div>

        <masthead></masthead>

        <div class="wrapper wrapper--grey wrapper--full" v-if="dealer">
            <div class="wrapper">

                <div class="dealerdetails dealerfinance">

                    <div class="dealerdetails__left">

                        <div class="dealerdetails__dealer">
                            <div class="dealerdetails__dealer_top">
                                <div class="dealerdetails__dealer_topleft">
                                    <div class="avatar avatar--medium" :style="getAvatar()"></div>
                                </div>
                                <div class="dealerdetails__dealer_topright">
                                    <h2>{{this.dealer.dealer.display_name}}</h2>
                                    <div class="stars stars--med" v-if="true==false">
                                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+100"></span>
                                    </div>
                                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                                </div>
                            </div>
                            <div class="dealerdetails__dealer_bottom">
                                <address>
                                    {{ formattedAddress1 }}<br/>
                                    {{ formattedAddress2 }}<br/>
                                    {{ formattedAddress3 }} {{ formattedAddress4 }}
                                </address>
                                <span class="telephone">{{ formattedPhone }}</span>
                                <span class="web"><a rel="noopener" :href="formattedWeb" target="_blank" class="green">{{ formattedWeb }}</a></span>
                            </div>
                        </div>

                        <div class="dealerdetails__info" id="dealerdetails__info" data-class="info-expand">
                            <p style="color: #000">By completing this finance application form the dealer will be able to quickly and
                            accurately get back to you with a decision. Cars must be worth €4,000 or more.</p>
                            <p style="color: #000">You can be assured that:</p>
                            <ul style="list-style-type: disc; font-size: 14px">
                              <li style="margin-left: 1em">There is no obligation to purchase anything</li>
                              <li style="margin-left: 1em">Competitive APRs offered</li>
                              <li style="margin-left: 1em">Our form is quick and simple to complete</li>
                            </ul>
                        </div>
                        <div class="grid grid--usedcars grid--usedcars--narrow">
                            <div class="usedcars_flex_item usedcars_flex_item--right">

                                <div class="wrapper" v-if="adId && dealerAds.length > 0">
                                    <div class="car-outer-box">
                                        <div class="car-summary-box">
                                            <div class="box-right">

                                                <h2>
                                                    Your Vehicle Summary
                                                </h2>
                                                <p>
                                                    Finance for {{ dealerAds[0].year }} {{ dealerAds[0].make }} {{ dealerAds[0].model }} <br>
                                                    {{ dealerAds[0].variant }}
                                                </p>

                                                <div class="car-price">
                                                    {{ formattedPrice }}
                                                </div>

                                            </div>
                                            <div class="box-left">
                                                <div class="img-container">
                                                    <img v-if="dealerAds[0].image" class="ad-image" v-bind:src="dealerAds[0].image.primaryURL.large"  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="wrapper" v-if="dealerAds.length > 0 && this.token == null">
                                    <div class="form-outer-box">
                                        <h3>Loan Details</h3>
                                        <hr>
                                        <form method="post" @submit.prevent="submitForm" @keydown="errors.clearAll()">
                                            <div class="form-group">
                                                <label for="vehicle" class="col-form-label">Select Your Vehicle</label>
                                                <select class="form-control" id="vehicle" name="vehicle" :readonly="checkReadOnly" @change="populateForm">
                                                    <option v-if="!this.adId">Please Select</option>
                                                    <option v-bind:value="idx" v-for="(ad, idx) in this.dealerAds" v-bind:key="idx">{{ ad.slug }}</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="price" class="col-form-label">Car Price</label>
                                                <input type="text" id="price" name="price" placeholder="" v-model="price" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="year" class="col-form-label">Year of Car</label>
                                                <input type="text" id="year" name="year" placeholder="" v-model="year" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="make" class="col-form-label">Vehicle Make</label>
                                                <input type="text" id="make" name="make" placeholder="" v-model="make" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="model" class="col-form-label">Vehicle Model</label>
                                                <input type="text" id="model" name="model" placeholder="" v-model="model" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="variant" class="col-form-label">Vehicle Variant</label>
                                                <input type="text" id="variant" name="variant" placeholder="" v-model="variant" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="mileage" class="col-form-label">Vehicle Mileage</label>
                                                <input type="text" id="mileage" name="mileage" placeholder="" v-model="mileage" :readonly="checkReadOnly" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="cash-deposit" class="col-form-label">Cash Deposit</label>
                                                <input type="text" id="cash-deposit" name="cash-deposit" placeholder="" v-model="cashDeposit" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="trade-in" class="col-form-label">Estimated Value of Trade-In</label>
                                                <input type="number" id="trade-in" name="trade-in" placeholder="" v-model="tradeInValue"  class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label for="trade-in-outstanding" class="col-form-label">Outstanding Loan on Trade-In</label>
                                                <input type="number" id="trade-in-outstanding" name="trade-in-outstanding" placeholder="" v-model="tradeInOutstanding" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <div class="empty-btn"></div>
                                                <div class="submit-outer">
                                                    <div v-if="errors.has('totalDeposit')">
                                                        <div class="form-error"
                                                             v-for="(error, index) in errors.get('totalDeposit')" :key="index" v-text="error">
                                                        </div>
                                                    </div>
                                                    <input type="submit" value="Submit">
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>

                                <div class="iframe-container">
                                    <div id="proposal-plugin-container" class="proposal-plugin-container">
                                    </div>
                                    <div id="success" class="msg-success">
                                        <p>
                                            Thank you for submitting your application for finance with us. <br>
                                            We are processing your application and we look forward to speaking with you soon!
                                        </p>
                                    </div>
                                </div>

                                <modal-error-message  :err="errorMessage" v-if="modalOpen"></modal-error-message>

                            </div>
                        </div>

                    </div>

                    <div class="dealerdetails__right">

                        <dealer-details-right-dealer :data="this.dealer" detailsModifier="dealerdetails__directions_details--desktop"></dealer-details-right-dealer>


                    </div>

                </div>

            </div>

        </div>

        <!--<floating-buttons-dealer></floating-buttons-dealer>-->
        <main-footer></main-footer>
    </div>
</template>

<script>

    import ModalErrorMessage from "../components/partials/ModalErrorMessage";

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }

        clearAll() {
            this.errors = {};
        }
    }

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import Masthead from  '@/components/partials/Masthead'
    import DealerDetailsRightDealer from '@/components/partials/DealerDetailsRightDealer'
    import {mapMutations, mapState, mapActions} from "vuex"
    import SeoMeta from "../components/partials/SeoMeta"
    import Loading from 'vue-loading-overlay';
    var numeral = require('numeral');
    import NativeEventBus from "../native-event-bus";
    import EventBus from "../event-bus";
    // import FloatingButtonsDealer from '@/components/partials/FloatingButtonsDealer'

    export default {
        name: "DealerFinance",
        components: {
            ModalErrorMessage,
            MainHeader,
            MainFooter,
            DealerDetailsRightDealer,
            Masthead,
            Breadcrumbs,
            Loading,
            SeoMeta
            // FloatingButtonsDealer,
        },
        props: {
            dealerSlug: {
                type: String,
                required: true
            },
            adId: {
                type: String,
                required: false
            },
            deposit: {
                type: String,
                required: false
            }
        },
        data: function() {
            return {
                errorMessage: null,
                modalOpen:false,
                readMoreActivated: false,
                aboutText:'',
                is_simi: false,
                fullPage: true,
                checked: false,
                dealerAds: [],
                errors: new Errors(),
                vehicleId: null,
                vehicle: null,
                price: null,
                year: null,
                make: null,
                model: null,
                variant: null,
                mileage: null,
                cashDeposit: null,
                tradeInValue: null,
                tradeInOutstanding: null,
                selected:null,
                token:null,
                proposalReference:null,
                financeApplicationId:null
            }
        },
        serverPrefetch() {
            const dealer =  this.getDealer();
            if(this.dealer && typeof this.dealer.dealer === 'undefined'){
                this.$ssrContext.error404 = true;
                this.$ssrContext.redirect = '/404';
                return false;
            }
            return dealer;

        },
        mounted() {

            let that = this;
            EventBus.$on('closeModal', function () {
                that.modalOpen = false;
            });


            this.setUrl(process.env.VUE_APP_API_URL +'/api/ad-elastic-filters?dealer=' + this.dealerSlug);
            if (!this.dealer) {
                this.getDealer();
            }else{

                if(this.dealer && typeof this.dealer.dealer === 'undefined'){
                    this.$router.push({path:'/not-found/'});
                    return;
                }
                //Call dealer finance data
                if(this.adId){
                    this.getSingleAd(this.dealer.dealer.id, this.adId, this.deposit);
                }else{
                    this.getDealerAds(this.dealer.dealer.id);
                }
                this.updateBreadCrumb();
            }

            let codeweavers = document.createElement('script');
            codeweavers.setAttribute('src', process.env.VUE_APP_CODEWEAVERS_PLUGIN_URL + '/scripts/v1/platform/proposal-form');
            document.head.appendChild(codeweavers);

            window.NativeEventBus = new NativeEventBus;
            window.NativeEventBus.addEventListener('submitFinanceApplicationSuccessForm', event => {

                this.submitFinanceApplicationSuccessForm(event);
            });
        },
        computed: {
            ...mapState([
                'makes',
                'models',
                'years',

                'prices',
                'monthlyPrice',
                'deposits',
                'monthlyPrices',
                'locations',
                'counties',
                'mileages',
                'kilometres',
                'engineSizes',
                'transmissionTypes',
                'fuelTypes',
                'bodyTypes',
                'colours',
                'seats',
                'doors',
                'safetyRatings',
                'fuelConsumption',
                'environmental',
                'enginePowers',
                'accelerations',
                'financeDeposits',
                'searchMake',
                'searchMakeDesktop',
                'searchModel',
                'searchYearFrom',
                'searchYearTo',
                'searchPriceFrom',
                'searchPriceTo',
                'searchLocations',
                'searchMileageFrom',
                'searchMileageTo',
                'searchKilometresFrom',
                'searchKilometresTo',
                'searchEngineSizeFrom',
                'searchEngineSizeTo',
                'searchTransmissionType',
                'searchFuelType',
                'searchBodyType',
                'searchColour',
                'searchSeats',
                'searchDoors',
                'searchSafetyRating',
                'searchFuelConsumption',
                'searchEnvironmental',
                'searchEnginePower',
                'searchAcceleration',
                'searchFinanceDeposit',

                'resultsLoaded',
                'searchResults',
                'url',
                'urlParams',
                'isLoading'
            ]),
            dealer() {
                return this.$store.state.dealers.dealer[this.dealerSlug];
            },
            checkReadOnly(){
                return (this.adId && this.dealerAds.length > 0 || this.selected)
            },
            formattedPrice(){
                return "€" + numeral(this.dealerAds[0].price).format('0,0');
            },
            formattedAbout(){
                let text = '';
                if(this.dealer != null && this.dealer.dealer!= null && this.dealer.dealer.about_us != null){
                    text = this.dealer.dealer.about_us.length > 200 ? this.dealer.dealer.about_us.slice(0, 200) + ' ...' : this.dealer.dealer.about_us;
                }
                return text;
            },
            formattedAboutFull(){
                return  this.dealer.dealer.about_us;
            },
            formattedAddress1() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address1 != null ? this.dealer.dealer.address.address1 : '';
            },

            formattedAddress2() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address2 != null ? this.dealer.dealer.address.address2 : '';
            },

            formattedAddress3() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address3 != null ? this.dealer.dealer.address.address3 : '';
            },

            formattedAddress4() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.address.address4 != null ? this.dealer.dealer.address.address4 : '';
            },

            formattedPhone() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.phone != null ? this.dealer.dealer.phone : '';
            },

            formattedWeb() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer.web != null ? this.dealer.dealer.web : '';
            },
            numberStars() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? Math.round(this.dealer.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.dealer != null && this.dealer.dealer != null && this.dealer.dealer != null && this.dealer.dealer.average_rating != null ? numeral(this.dealer.dealer.average_rating).format('0.00') : 0;
            },
        },
        methods: {
            ...mapMutations([
                'setMakes',
                'setModels',
                'setYears',
                'setPrices',
                'setLocations',
                'setCounties',
                'setMileages',
                'setKilometres',
                'setEngineSizes',
                'setTransmissionTypes',
                'setFuelTypes',
                'setBodyTypes',
                'setColours',
                'setSeats',
                'setDoors',
                'setMonthlyPrices',
                'setSafetyRatings',
                'setFuelConsumptions',
                'setEnvironmentals',
                'setEnginePowers',
                'setAccelerations',
                'setFinanceDeposits',
                'setOpenMobileRefine',
                'setSearchMake',
                'setSearchMakeDesktop',
                'unsetSearchMake',
                'setSearchModel',
                'setSearchModelDesktop',
                'unsetSearchModel',
                'setSearchYearFrom',
                'setSearchYearTo',
                'setSearchPriceFrom',
                'setSearchPriceTo',
                'setSearchLocations',
                'setSearchMileageFrom',
                'setSearchMileageTo',
                'setSearchKilometresFrom',
                'setSearchKilometresTo',
                'setSearchMonthlyPricesFrom',
                'setSearchMonthlyPricesTo',
                'setSearchEngineSizeFrom',
                'setSearchEngineSizeTo',
                'setSearchTransmissionType',
                'setSearchFuelType',
                'setSearchBodyType',
                'setSearchColour',
                'setSearchSeats',
                'setSearchDoors',
                'setSearchSafetyRating',
                'setSearchFuelConsumption',
                'setSearchEnvironmental',
                'setSearchEnginePower',
                'setSearchAcceleration',
                'setSearchFinanceDeposit',
                'setCurrentFilter',
                'setSearchOrderBy',
                'setRequestedPage',
                'setSearchFilters',
                'setJustLoaded',
                'setUrlParams',
                'setSearchResults',
                'setBackendPagination',
                'setCurrentPage',
                'setCurrentPageName',
                'setPerPage',
                'setTotal',
                'setFrom',
                'setTo',
                'setResultsLoaded',
                'setUrl',
                'setIsLoading',
                'resetSearchVars',
                'setDealer',
                'setInternalSource'
            ]),
            ...mapActions([
                'syncLoadMakesAction',
                'updateUrlAction'
            ]),
            getAvatar(){
                return this.dealer.dealer.team_members != null && this.dealer.dealer.team_members.length > 0 ? 'background-image: url(' + this.dealer.dealer.team_members[0].photo_url + ')' : '';
            },
            validateForm(){

                let isValid = true;
                let totalDeposit = 0;
                let tenPercent = Math.ceil(numeral(this.price).format('0') * 0.1);

                if(!this.cashDeposit && !this.tradeInValue && !this.tradeInOutstanding){
                    isValid = false;
                }

                if(parseInt(this.cashDeposit) > 0){
                    totalDeposit += parseInt(this.cashDeposit.replace(/,/g, ""));
                }

                if(parseInt(this.tradeInValue) > 0){
                    totalDeposit += parseInt(this.tradeInValue);
                }


                if(parseInt(this.tradeInOutstanding) > 0){
                    totalDeposit -= parseInt(this.tradeInOutstanding);
                }

                // If less than 10 percent error
                if(totalDeposit < tenPercent || totalDeposit == 0){
                    isValid = false;
                    let error = {totalDeposit: ['Deposit / trade-in must be at least 10% (€' + numeral(tenPercent).format('0,000') + ')']};
                    this.errors.record(error);
                }

                if(isValid){
                    this.errors.clearAll();
                }

                return isValid;
            },
            submitForm(){

                if(this.validateForm()){
                    this.submitFinanceForm();
                }

            },
            populateForm($event){

                this.selected = this.dealerAds[$event.target.value] != null ? this.dealerAds[$event.target.value] : null;

                if(this.selected){
                    this.price = numeral(this.selected.price).format('0,000');
                    this.vehicleId = this.selected.id;
                    this.year = this.selected.year;
                    this.make = this.selected.make;
                    this.model = this.selected.model;
                    this.variant = this.selected.variant;
                    this.mileage = this.selected.mileage;
                    this.cashDeposit = numeral((this.selected.price * 10) / 100).format('0,000');
                }else{
                    this.vehicleId = null;
                    this.price = null;
                    this.year = null;
                    this.make = null;
                    this.model = null;
                    this.variant = null;
                    this.mileage = null;
                    this.cashDeposit = null;
                }

            },
            toggleShowMore(){
                this.readMoreActivated = !this.readMoreActivated;
            },
            getDealerAds(dealerId){

                if(dealerId != null){
                    this.setIsLoading(true);
                    this.axios.get(process.env.VUE_APP_API_URL + '/api/dealer-ads/' + dealerId ).then((response) => {
                        this.dealerAds = response.data;
                        this.setIsLoading(false);
                    });
                }

            },
            getSingleAd(dealerId, adId, deposit){
                if(dealerId != null){
                    this.setIsLoading(true);
                    this.axios.get(process.env.VUE_APP_API_URL + '/api/dealer-ads/' + dealerId + '/' + adId ).then((response) => {
                        this.dealerAds.push(response.data);
                        this.vehicleId = response.data.id;
                        this.vehicle = response.data.slug;
                        this.price = numeral(response.data.price).format('0,000');
                        this.year = response.data.year;
                        this.make = response.data.make;
                        this.model = response.data.model;
                        this.variant = response.data.variant;
                        this.mileage = response.data.mileage;
                        if(deposit > 0){
                            this.cashDeposit = numeral(deposit).format('0,000');
                        }else{
                            this.cashDeposit = numeral(response.data.price * 10 / 100).format('0,000');
                        }

                        this.setIsLoading(false);
                    });
                }
            },
            submitFinanceApplicationSuccessForm(evt){
                this.setIsLoading(true);
                this.axios.post(process.env.VUE_APP_API_URL + '/api/finance/create-finance-application',{
                    'id': this.financeApplicationId,
                    'ad_id': this.vehicleId,
                    'term' : null,
                    'cash_deposit': parseInt(this.cashDeposit.replace(/,/g, "")),
                    'tradein_value': this.tradeInValue,
                    'tradein_outstanding': this.tradeInOutstanding,
                    'step1_completed': true,
                    'step2_completed': true
                }).then((response) => {
                    let res = response.data;
                    this.setIsLoading(false);
                    window.scrollTo(0, 0);
                    console.log("Finance Application Completed Successfully", evt, res);
                });
            },
            submitFinanceForm(){
                let that = this;
                this.setIsLoading(true);
                this.axios.post(process.env.VUE_APP_API_URL + '/api/finance/finance-quote',{
                    'vehicle': this.vehicleId,
                    'cash-deposit': parseInt(this.cashDeposit.replace(/,/g, "")),
                    'trade-in': this.tradeInValue,
                    'trade-in-outstanding': this.tradeInOutstanding,
                }).then((response) => {
                    let res = response.data;
                    this.token = res.token;
                    this.proposalReference = res.proposalReference;
                    this.financeApplicationId = res.financeApplicationId;
                    this.appendCodeweavers();
                    let offset = this.adId && this.dealerAds.length > 0 ? 160 : -100;
                    window.scrollTo(0, document.getElementById("dealerdetails__info").offsetTop + offset);
                    this.setIsLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        this.setIsLoading(false);
                        this.errorMessage = error.response.data.errors.api;
                        this.modalOpen = true;
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        this.setIsLoading(false);
                        this.errorMessage = ''
                        this.modalOpen = true;
                        // console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.setIsLoading(false);
                        this.errorMessage = '';
                        this.modalOpen = true;
                        // console.log('Error', error.message);
                    }
                    // console.log(error.config);
                });
            },
            appendCodeweavers(){
                let myScript = document.createElement('script');
                let myElement = document.getElementById("proposal-plugin-container");
                let data = "" +
                    "                codeweavers.proposalForm.main({\n" +
                    "                    pluginContentDivId: 'proposal-plugin-container',\n" +
                    "                    data: {\n" +
                    "                        authToken: '" + this.token + "',\n" +
                    "                        proposalReference: '" + this.proposalReference + "'\n" +
                    "                    },\n" +
                    "                    onDomainEvent: function (event, data) {\n" +
                    "                        if (event == 'proposal.successful-submission' && data == true) {\n" +
                    "                            document.getElementById('proposal-plugin-container').style.display = 'none'; \n" +
                    "                            document.getElementById('success').style.display = 'block'; \n" +
                    "                            window.NativeEventBus.dispatchEvent('submitFinanceApplicationSuccessForm'); \n" +
                    "                        }\n" +
                    "                    },\n" +
                    "                    utmParameters: { \n" +
                    "                       utmSource: 'terrific.ie' \n" +
                    "                    }\n" +
                    "                });" +
                    "";
                myScript.appendChild(document.createTextNode(data));
                myElement.appendChild(myScript);
            },
            async getDealer() {
                this.setIsLoading(true);
                await this.$store.dispatch('dealers/fetchDealer', this.dealerSlug);
                if(typeof this.dealer.dealer === 'undefined'){
                    return false;
                }
                this.setDealer(this.dealer.dealer);
                this.setIsLoading(false);

                //Call dealer finance data
                if(this.adId){
                    this.getSingleAd(this.dealer.dealer.id, this.adId, this.deposit);
                }else{
                    this.getDealerAds(this.dealer.dealer.id);
                }

                this.updateBreadCrumb();
            },
            updateBreadCrumb(){
                if(this.dealer == null){
                    return false;
                }

                let dealerName = this.dealer.dealer.display_name;
                let dealerSlug = this.dealer.dealer.slug;

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  dealerName);

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setSecondLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelFour',  false);
                this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                this.$store.commit('breadcrumbs/setLeafLevelName',  'Finance');
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/dealer/' + dealerSlug);

            }
        }
    }
</script>

<style scoped>

</style>
